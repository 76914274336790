(function ($) {
    // sticky nav
    function bcorr_stickyNav(selector) {
        var newnav;
        var addContainer = true;
        var append = null;
        if (selector == null) {
            newnav = bcorr.navigation;
        } else {
            newnav = $(selector);
            addContainer = false;
        }

        var nav = $('<div id="bcorr-nav-wrapper"></div>');
        var wrap = $('<div id="bcorr-nav"></div>');
        wrap.appendTo(nav);

        append = wrap;

        if (addContainer) {
            var container = $('<div class="container"></div>');
            container.appendTo(wrap);

            append = container;
        }

        nav.insertAfter(newnav);
        newnav.appendTo(append);

        function resizeNavWrapper() {
            if ($('body.bcorr-nav-follow').length > 0) {
                var height = $('#bcorr-nav').height();
                $('#bcorr-nav-wrapper').css('height', height + 'px');
            } else {
                $('#bcorr-nav-wrapper').css('height', 'auto');
            }
        }

        $(window).resize(function (event) {
            resizeNavWrapper();
        });

        $(window).scroll(function (event) {
            var navTop = $('#bcorr-nav-wrapper').offset().top;
            if ($(window).scrollTop() >= navTop) {
                $("body").addClass('bcorr-nav-follow');
            } else {
                $("body").removeClass('bcorr-nav-follow');
            }
            resizeNavWrapper();
        });

        $(window).trigger('resize');
    }

    bcorr_stickyNav('#bcorr-navigation');

    if ($('body.home').length > 0) {
        initScroll();
    }

    function initScroll() {
        $('a[href^="#"]').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                console.log(target);
                if (target.length) {
                    if (target.length) {
                        $('html, body').animate({
                            scrollTop: target.offset().top - $('#header').height()
                        }, 1000);
                    }
                    return false;
                }
            }
        });
    }

    $('#bcorr-section-1 .bcorr-title-col h3').attr('data-0-bottom-top', 'left: 0%');
    $('#bcorr-section-1 .bcorr-title-col h3').attr('data-0-bottom-bottom', 'left: 50%');

    // $('#bcorr-section-3 .col:first-child').attr('data-0-bottom-top', 'transform: translateX(-200px);  opacity: 0;');
    // $('#bcorr-section-3 .col:first-child').attr('data-0-bottom-bottom', 'transform: translateX(0);  opacity: 1;');

    $('#bcorr-section-3 .col:last-child').attr('data-0-bottom-top', 'transform: translateX(200px); opacity: 0;');
    $('#bcorr-section-3 .col:last-child').attr('data-0-bottom-bottom', 'transform: translateX(0); opacity: 1;');

    $('#bcorr-header .parallax').attr('data-0-top-top', 'transform: translateY(-100px)');
    $('#bcorr-header .parallax').attr('data-0-top-bottom', 'transform: translateY(100px)');

    $('#bcorr-section-2 .bcorr-background').attr('data-0-bottom-top', 'transform: scale(1);');
    $('#bcorr-section-2 .bcorr-background').attr('data-0-bottom-bottom', 'transform: scale(1.1);');

    $('#bcorr-section-4 .bcorr-background').attr('data-0-bottom-top', 'transform: scale(1.1);');
    $('#bcorr-section-4 .bcorr-background').attr('data-0-bottom-bottom', 'transform: scale(1.0);');

    // PARALLAX STARTER 
    $.getScript("https://d3sw26zf198lpl.cloudfront.net/2017/10/03/59d3e983405be.js", function () {
        var s = skrollr.init({
            render: function (data) {
                //Debugging - Log the current scroll position.
                //console.log(data.curTop);
            }
        });
        if (s.isMobile()) {
            s.destroy();
        }

    });

    // Guide Image
    function proofguide(proof) {
        var button = $('<div id="bcorr-guide-toggle">');
        var guide = $('<div id="bcorr-guide" class="hide">');
        var img = $('<img>');
        img.attr('src', proof);
        img.appendTo(guide);

        guide.appendTo('body');
        button.appendTo('body');

        $("#bcorr-guide-toggle").on('click', function (event) {
            $("#bcorr-guide").toggleClass("hide");
        });
        $("#bcorr-guide img").on('click', function (event) {
            $("#bcorr-guide").toggleClass("light");
        });

        $(window).resize(function () {
            var screenImage = $("#bcorr-guide img");

            // Create new offscreen image to test
            var theImage = new Image();
            theImage.src = screenImage.attr("src");

            // Get accurate measurements from that.
            var imageWidth = theImage.width;
            if (imageWidth > $(window).width()) {
                $("#bcorr-guide img").css("-webkit-filter", "invert(1)");
            } else {
                $("#bcorr-guide img").css("-webkit-filter", "none");
            }
        });
    }

    // proofguide('/wp-content/themes/pp-brokerage-v1.1-children/kw-falls-church/resources/guide.jpg');

})(jQuery);
